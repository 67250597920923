@import "../abstracts/abstracts-module";

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:hover {
        opacity: .8;
    }
    &:disabled {
        opacity: .4;
    }
}