@import "../abstracts/abstracts-module";

footer {
	background-color: $primary;
    //padding: $spacer * 5 0 $spacer * 4 0;

    h3 {
        color: $white;
        & + a {
            color: $secondary;
            font-size: $font-size-base * 1.5;
            font-weight: $font-weight-bold;
        }
    }

    ul.nav {
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        li.nav-item {
            text-align: center;
            a.nav-link {
                color: $white !important;
                //font-size: $font-size-base * 1.5;
                font-weight: $font-weight-bold;
                padding: 0;
            }
        }
    }

}

@include media-breakpoint-down(lg) { 
    footer {
        ul.nav {
            display: flex;
            flex-direction: column;
        }
    }
}
