@import "../abstracts/abstracts-module";

$font-size-base-px: 16px;

html {
  /*-webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;*/
  font-size: $font-size-base-px !important;
  /*@include media-breakpoint-down(sm) {
    font-size: $font-size-base*0.8;
  }
  @include media-breakpoint-up(rt15) {
    font-size: $font-size-base*1.1;
  }
  @include media-breakpoint-up(uhd) {
    font-size: $font-size-base*1.2;
  }
  @include media-breakpoint-up(4k) {
    font-size: $font-size-base*1.2;
  }
  @include media-breakpoint-up(8k) {
    font-size: $font-size-base*1.3;
  }*/
}

a:hover {
  opacity: 0.8;
}

h2 {
  margin-bottom: $spacer * 5;
  position: relative;
}

h2::after {
  content: "";
  background-color: $primary;
  width: 100%;
  height: $spacer * .5;
  position: absolute;
  bottom: -$spacer * 2;
  left: 0;
  right: 0;
}

h3, h4 {
  color: $primary;
  margin-bottom: $spacer;
}

b,
strong {
  font-weight: $font-weight-semibold;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.text-primary {
  color: $primary !important;
}