@import "../abstracts/abstracts-module";

// Selection
::selection,
::moz-selection {
	color: $white !important;
	background-color: $primary !important;
}

// Focus
*:focus,
*.focus {
	box-shadow: none !important;
	outline: none !important;
}
*::-moz-focus-inner {
	border: 0;
	outline: none !important;
}

// Scrollbars
@include scrollbars($spacer * .5, $light);